import React, { useState, useEffect } from 'react'
import '@/views/privacy_policy.css'
import Sidebar from '@/views/sidebar'
import support_fr from '@/markdown/fr/support.md';
import support_en from '@/markdown/en/support.md';
import MarkdownContainer from '@/views/markdown_container'

const Support = () => {

  const [browserLanguage, setBrowserLanguage] = useState(null)
  const [markdownSource, setMarkdownSource] = useState('')

  useEffect(() => {
    // Get the browser's language from the navigator object
    const language = navigator.language || navigator.userLanguage;
    setBrowserLanguage(language);
  }, [])


  const getSupport = () => {
    const fileToFetch = browserLanguage?.startsWith('fr') ? support_fr : support_en

    fetch(fileToFetch)
     .then(r => r.text())
     .then(text => {
       setMarkdownSource(text)
     });

    return (
      <div className="app-container">
        <Sidebar />
        <MarkdownContainer markdownSource={markdownSource} />
      </div>
      )
  }


  return getSupport()
}

export default Support
