import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import '@/views/markdown_container.css'

const MarkdownContainer = (props) => {
    
    const { markdownSource } = props
    
    return (
        <div className="markdown-container">
            <Markdown remarkPlugins={[remarkGfm]}>{markdownSource}</Markdown>
        </div>
    )
}

export default MarkdownContainer
