import React from 'react'
import ReactDOM from 'react-dom'
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import '@/style.css'
import Landingpage from '@/views/landingpage'
import PrivacyPolicy from '@/views/privacy_policy'
import Support from '@/views/support'
import PageNotFound from "@/views/pagenotfound";
import RedirectToApp from "@/views/redirect_to_app";

const helmetContext = {};

const App = () => {
  return (
    <HelmetProvider context={helmetContext}>
      <BrowserRouter>
        <Routes>
          <Route element={<Landingpage />} exact path="/" />
          <Route element={<PrivacyPolicy />} exact path="/privacy_policy" />
          <Route element={<Support />} exact path="/support" />
          <Route element={<PageNotFound />} path="*" />
          <Route path="trips">
            <Route element={<RedirectToApp />} path=":id" />
          </Route>
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
