import React, { useState, useEffect } from 'react'
import '@/views/privacy_policy.css'
import Sidebar from '@/views/sidebar'
import privacy_policy_fr from '@/markdown/fr/privacy_policy.md';
import privacy_policy_en from '@/markdown/en/privacy_policy.md';
import MarkdownContainer from '@/views/markdown_container'

const PrivacyPolicy = () => {

  const [browserLanguage, setBrowserLanguage] = useState(null)
  const [markdownSource, setMarkdownSource] = useState('')

  useEffect(() => {
    // Get the browser's language from the navigator object
    const language = navigator.language || navigator.userLanguage;
    setBrowserLanguage(language);
  }, [])


  const getPrivacyPolicy = () => {
    const fileToFetch = browserLanguage?.startsWith('fr') ? privacy_policy_fr : privacy_policy_en

    fetch(fileToFetch)
     .then(r => r.text())
     .then(text => {
       setMarkdownSource(text)
     });
  
    return (
      <div className="app-container">
        <Sidebar />
        <MarkdownContainer markdownSource={markdownSource} />
      </div>
    )
  }
  

  return getPrivacyPolicy()
}

export default PrivacyPolicy
