import React from 'react'
import '@/views/landingpage.css'

const PageNotFound = () => {
  return (
    <div className="landingpage-container">
      Page not found
    </div>
    )
}

export default PageNotFound
