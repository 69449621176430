import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const RedirectToApp = () => {
    const { id } = useParams();
    
    const navigate = useNavigate();

    useEffect(() => {
        const isMobile = /iPhone|iPod/i.test(navigator.userAgent);
        const deepLink = `com.nomadsexplorer.nomads://trips/${id}`;
        const fallbackURL = isMobile ? 'https://apps.apple.com/fr/app/nomads-explorer/id6464514540' : 'https://getnomadsapp.com'

        const timeout = setTimeout(() => {
            window.location.href = fallbackURL;
        }, 10000);

        window.location.href = deepLink; // Attempt to open the app

        return () => clearTimeout(timeout); // Cleanup the timeout if the component unmounts
    }, [navigate]);

    return (
        <div>Redirecting...</div>
    );
};

export default RedirectToApp;
