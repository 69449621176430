import React from 'react'
import '@/views/sidebar.css'
import logo from '@assets/logo.svg'

const Sidebar = () => {
  return (
    <div className="header-container">
      <div className="logo-container">
        <img
          className="logo-image"
          src={logo}
          alt="Device frame"
        />
        <div className="logo-text-container">
          <h1 className="logo-text">nomads</h1>
          <div className="logo-underline"></div>
        </div>
      </div>
      <div className="sidebar-navigation">
        <a href="/">Home</a>
        <a href="/support">Support</a>
        <a href="/privacy_policy">Privacy policy</a>
        {/*<a href="/terms_od_use">Terms of use</a>*/}
      </div>
      <span className="outline-text">
        Since 2023
      </span>
    </div>
  )
}

export default Sidebar
