import React from 'react'
import Sidebar from '@/views/sidebar'
import '@/views/landingpage.css'
import deviceFrame from '@assets/device_frame.png'

const Landingpage = () => {
  return (
    <div className="app-container">
      <Sidebar />
      <div className="first-section-container">
        <h2 className="title-text">
          The future of traveling is there.
        </h2>
        <div className="first-section-content">
          <span className="content-text">
            Every trip is a chance to inspire, connect, and leave a trail of wanderlust behind. Remember, the world is waiting to hear your tales of wander and wonder! Share your journeys, sprinkle some travel magic, and let's create a global community of adventurers united by their love for exploration! 🌟🌏✨
          </span>
          <div className="download-buttons-container">
            <a href="https://apps.apple.com/fr/app/nomads-explorer/id6464514540">
              <img className="store-button" src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg" alt="AppStore Download Button"/>
            </a>
          </div>
        </div>
      </div>
      <div className="second-section-container">
        <img
          className="device-frame-image"
          src={deviceFrame}
          alt="Device frame"
        />
      </div>
    </div>
  )
}

export default Landingpage
